/*
 * @Author: lixiao
 * @Email: l3936525@163.com
 * @Filename: webpanel/src/apis/aisep/model/auto.js
 * @Date: 2021-08-16 14:48:08
 * @Last Modified by: lixiao
 * @Last Modified time: 2021-11-02 14:59:36
 * @Description: 
 */

import utils from "webasercomps/src/assets/utils";
import { generateFlatApi } from "webasercomps/src/apis/utils";

const {fetch, create, modify} = generateFlatApi({
	fetchURL: '/v3/aisep/auto/fetch/model',
	createURL: '/v3/aisep/auto/create/model',
	modifyURL: '/v3/aisep/auto/modify/model',
	params: function(oper){
		return {type: 'car'};
	},
	recordProcessor: function(records, oper){
		if(oper === 'fetch' && utils.isObject(records)){
			return Object.keys(records).map(key => {
				let record = records[key];
				return {
					...record.brand,
					children: record.models
				}
			})
		}

		return records;
	}
});

export {
	fetch, create, modify
}

export const eps = {
	FETCH_AUTO_MODELS: '/fetch/auto/models',
	CREATE_AUTO_MODEL: '/create/auto/model',
	MODIFY_AUTO_MODEL: '/modify/auto/model'
}