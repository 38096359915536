/*
 * @Author: lixiao
 * @Email: l3936525@163.com
 * @Filename: webpanel/src/pages/index/index.js
 * @Date: 2021-10-30 16:02:37
 * @Last Modified by: lixiao
 * @Last Modified time: 2021-11-07 09:29:54
 * @Description: 
 */

import React from 'react';
import ReactDom from 'react-dom'

import {Provider} from 'react-redux'

import {
	BrowserRouter as Router, Route
} from 'react-router-dom';

import {
	createWebaserStore,
	createReducer,
	actions,
	Webaser
} from 'webasercomps';

import {
	api
} from '../../apis';

import config from '@config';

const devTool = typeof config.devTool !== 'undefined'?config.devTool:true;

const webaserStore = createWebaserStore({api, createReducer, devTool});
const store = webaserStore.getStore();

store.dispatch(actions.receiveConfig(config));

ReactDom.render(
	<Provider store={store}>
		<Router>
			<Route path="/" render={
				props => (
					<Webaser devTool={devTool}/>
				)
			}/>
		</Router>
	</Provider>,
	document.getElementById('webaser')
);