/*
 * @Author: lixiao
 * @Email: l3936525@163.com
 * @Filename: webpanel/src/apis/aisep/theme/ref.js
 * @Date: 2021-09-04 16:25:39
 * @Last Modified by: lixiao
 * @Last Modified time: 2021-11-02 10:56:03
 * @Description: 
 */

import { generateFlatApi } from "webasercomps/src/apis/utils";

const {fetch, create} = generateFlatApi({
	fetchURL: '/v3/aisep/ticket/theme/fetch/refs',
	createURL: '/v3/aisep/ticket/theme/bind'
});

export {
	fetch, create
}

export const eps = {
	FETCH_THEME_REFS: '/fetch/ticket/theme/refs',
	BIND_THEME_REF: '/bind/ticket/theme'
}